
import { defineComponent } from 'vue';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
//import * as Yup from "yup";
import AttendanceUpdateDrwaer from '@/layout/header/partials/attendance/attendanceupdateDrawer.vue';
import ApiService from '@/core/services/ApiService';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'internship-trainee-attendance',
  components: {},
  data() {
    return {
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        term_id: '',
        batch_id: '',
        total_terms: '',
      },

      id: '',
      image: '',
      registration_number: '',
      name: '',
      is_present: false,
      is_late: false,
      day_selected: false,
      attendance_date: '',
      in_time: '',
      out_time: '',
      remarks: '',

      trainee_reg: '',

      secondaryClass: 'btn-secondary ',
      dangerClass: 'btn-danger',
      employee: {},
      batchList: [],
       trainee_id : '' as any,
      checkedItems: [] as any,
      termList: [],
      attendanceList: [] as any,
      details: {},
      tranches_info: [],
      traineeInfo: [] as any,
      weekplan: {},
      entityTypes: [],
      present: '',
      late: '',
      courseList: [],
      TraineeList: [] as any,
      industry: [],
      entityInfos: [],
      divisions: [],
      trainingCalendar: [] as any,
      instituteList: [],
      is_attendance_given: 0,
      componentKey: 0,
      tableData: [],
      form: {},
      moment: '' as any,
      loading: false,
      load: false,
      showcalendarDetails: false,
    };
  },

  async created() {
    this.moment = moment;
    await this.getTranches();
    await this.getEntityInfos();
    this.emitter.on('attendance-updated', async () => {
      this.calenderDetails();
    });
  },

  methods: {
     is24HourFormat(time) {
      const [hours] = time.split(':');
      console.log(parseInt(hours));
      return parseInt(hours) >=12;
    },
    isChecked(value) {
      console.log('isChecked method called');
      console.log('checkedItems:', this.checkedItems);
      console.log('value:', value);
      return (value) => this.checkedItems.includes(value);
    },
    toggleCheckbox(value) {
      console.log('toggleCheckbox method called');
      console.log('checkedItems (before):', this.checkedItems);
      console.log('value:', value);
      const index = this.checkedItems.indexOf(value);
      if (index !== -1) {
        // Value already exists, remove it from the array
        this.checkedItems.splice(index, 1);
      } else {
        // Value does not exist, add it to the array
        this.checkedItems.push(value);
      }
      console.log(this.checkedItems);
    },

    async formSubmit() {
      this.loading = true;
      let form = new FormData();

      form.set('attendanceList', JSON.stringify(this.attendanceList));
      form.set('entity_id', this.batch?.entity_id);
       form.set('trainee_profile_id', this.trainee_id);
      form.set('term_no', this.batch.term_id);
      form.set('tranche_id', this.batch.tranche_id);
      form.set('institute_id',  this.batch.institute_id);
      form.set('course_id',  this.batch.course_id);
      form.set('batch_id',  this.batch.batch_id);
       
     
     
      let user_id = VueCookieNext.getCookie('_seip_user');
      form.set('user_id', user_id.id);
      form.set('registration_number', this.trainee_reg);

      await ApiService.post(
        this.VUE_APP_TRAINEE_INTERNSHIP_ATTENDANCE_SAVE_API,
        form
      )
        .then((response) => {
          this.loading = false;
          if (response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {

              this.attendanceList = [];
              this.showcalendarDetails = false;


            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },

    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async calenderDetails() {
      if (this.batch.term_id && this.batch.batch_id && this.trainee_reg ) {
        this.load = true;
        this.showcalendarDetails = true;

        await ApiService.get(
          'trainee/attendance-report-intern?registration_number=' + this.trainee_reg + '&batch_info_id=' + this.batch.batch_id + '&term_id=' + this.batch.term_id
        )
          .then((response) => {
            this.load = false;
            this.attendanceList = response.data.data.trainee_attendance_info;

            for (let i = 0; i < this.attendanceList.length; i++) {
              this.attendanceList[i].day_selected = (this.attendanceList[i].is_present === 1 || this.attendanceList[i].is_present === 0) ? true : false;
              this.attendanceList[i].is_present =
                this.attendanceList[i].is_present === 1 ? true : false;
              this.attendanceList[i].is_late =
                this.attendanceList[i].is_late === 1 ? true : false;
               
            }

            this.traineeInfo = response.data.data.trainee_info;
            this.trainee_id = response.data.data.trainee_info.id;
            // console.log(this.attendanceList);
            this.componentKey += 1;
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      }
    },
    async checkAttedenceDetails(data) {
      this.loading = true;
      await ApiService.post(this.VUE_APP_TRAINEE_ATTENDANCE_COUNT, data)
        .then((response) => {})
        .catch((response) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      this.batch.institute_id = '';
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.batch.term_id = '';
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }

      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainee() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }

      this.trainingCalendar = [];
      await ApiService.get(
        'trainee/list?entity_id=' +
          this.batch.entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&training_institute_id=' +
          this.batch.institute_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&batch_info_id=' +
          this.batch.batch_id
      )
        .then((response) => {
          this.loading = false;
          this.TraineeList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    edit(data) {
      console.log(data);
      this.emitter.emit('attendance-update-drawer-info', data);
    },
    async termInfo() {
      this.batch.term_id = '';

      this.trainingCalendar = [];
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_GET_BATCH_TERM_API_INTERN +
           '?course_info_id=' +
          this.batch.course_id 
      )
        .then((response) => {
          this.loading = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      this.batch.batch_id = '';
      this.batch.term_id = '';

      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async courseInfo() {
      this.loading = true;
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.batch.term_id = '';

      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
  },
});
